import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthorizedAtom, signIn, signOut, userIdAtom, type IAuthData } from 'auth';
import { useAtom, useAtomValue } from 'jotai';
import { ERoutes } from 'routing';
import { useSetLoading } from './useLoading';
import { useUser } from './useUser';

let initialPath: string | null = window.location.pathname + window.location.search;

export const useAuth = () => {
  const [userId] = useAtom(userIdAtom);
  const navigate = useNavigate();
  const isAuthorized = useAtomValue(isAuthorizedAtom);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const { startLoading, endLoading } = useSetLoading();
  const user = useUser();

  useEffect(() => {
    if (user.email && initialPath !== null) {
      if (user.changePassword) {
        navigate(ERoutes.Default);
      } else if (initialPath) {
        navigate(initialPath);
      } else {
        navigate(ERoutes.Default);
      }
      initialPath = null;
    }
  }, [user]);

  const signOutWrapper = async () => {
    try {
      startLoading('singOut');
      await signOut();
      endLoading('singOut');
    } catch (err) {
      console.error(err);
      endLoading('singOut');
      setErrorKey('base.baseError');
    }
  };

  const signInWrapper = async (values: IAuthData): Promise<void> => {
    try {
      startLoading('signIn');
      const { user, status } = await signIn(values);
      endLoading('signIn');
      if (status === 'WRONG_CREDENTIALS_ERROR') {
        return setErrorKey('login.credentialsError');
      }
      if (!user) {
        return setErrorKey('base.baseError');
      }
      setErrorKey(null);
    } catch (err) {
      console.error(err);
      endLoading('signIn');
      setErrorKey('base.baseError');
    }
  };

  return {
    signOut: signOutWrapper,
    signIn: signInWrapper,
    isAuthorized,
    userId,
    errorKey,
  };
};
