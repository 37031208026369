import { useState, type AnimationEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LogoCompany } from 'assets';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  LanguageSelector,
  Paper,
  TextField,
  Typography,
} from 'components';
import { useAuth, useLoading } from 'hooks';
import { ERoutes } from 'routing';

export const SignIn = () => {
  const { loading } = useLoading();
  const { t } = useTranslation();
  const { signIn, errorKey, isAuthorized } = useAuth();

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email(t('login.notValidEmail')).required('Required'),
    password: Yup.string().required('Required'),
  });

  const formik = useFormik({
    validationSchema: SignupSchema,
    initialValues: {
      email: '',
      password: '',
      isPasswordVisible: false,
    },
    onSubmit: signIn,
  });

  // In Chrome autofill doesn't fire onChange event.
  // Input looks like typed but value in formik is empty string so TextField is showing both: placeholder and value.
  // This is workaround to handle it.
  const [isEmailAutoFilled, setIsEmailAutoFilled] = useState(false);
  const [isPasswordAutoFilled, setIsPasswordAutoFilled] = useState(false);
  const makeAnimationStartHandler = (stateSetter: (val: boolean) => void) => (e: AnimationEvent<HTMLInputElement>) => {
    const autofilled = (e.target as unknown as HTMLInputElement)?.matches?.('*:-webkit-autofill');
    if (e.animationName === 'mui-auto-fill' || e.animationName === 'mui-auto-fill-cancel') {
      stateSetter(autofilled);
    }
  };

  const isButtonDisabled = loading
    ? true
    : isEmailAutoFilled && isPasswordAutoFilled
      ? false
      : !formik.values.email.trim() || !formik.values.password.trim();

  if (isAuthorized) {
    return <Navigate replace to={ERoutes.Default} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <Paper
          elevation={3}
          variant="elevation"
          sx={{
            position: 'relative',
            marginTop: 20,
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <Box sx={{ position: 'absolute', top: 21, right: 0 }}>
            <LanguageSelector />
          </Box>
          <Typography component="h1" variant="h5" align="center">
            {t('login.signIn')}
          </Typography>
          {errorKey && <Alert severity="error">{t(errorKey)}</Alert>}
          <TextField
            autoFocus
            autoComplete="email"
            name="email"
            label={t('login.email')}
            placeholder={t('login.enterEmail')}
            inputProps={{ onAnimationStart: makeAnimationStartHandler(setIsEmailAutoFilled) }}
            InputLabelProps={{ shrink: isEmailAutoFilled || undefined }}
            fullWidth
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.submitCount > 0 && formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.submitCount > 0 && formik.touched.email && formik.errors.email}
          />
          <TextField
            autoComplete="password"
            name="password"
            label={t('login.password')}
            placeholder={t('login.enterPassword')}
            inputProps={{ onAnimationStart: makeAnimationStartHandler(setIsPasswordAutoFilled) }}
            InputLabelProps={{ shrink: isPasswordAutoFilled || undefined }}
            fullWidth
            required
            type={formik.values.isPasswordVisible ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => formik.setFieldValue('isPasswordVisible', !formik.values.isPasswordVisible)}
                  >
                    {formik.values.isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button size="large" variant="contained" fullWidth disabled={isButtonDisabled} type="submit">
            {loading ? <CircularProgress size={26} /> : t('login.signIn')}
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <LogoCompany />
          </Box>
        </Paper>
      </form>
    </Container>
  );
};

export default SignIn;
