import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom, type WritableAtom } from 'jotai';
import { Group } from 'components/CheckboxGroup/Group';
import { type IGroupItem } from 'components/CheckboxGroup/GroupItem';
import { useUser } from 'hooks/useUser';
import { canSelectAndAllCluster } from './accessesFeature/accessGroupAtomHelpers';
import { type TClusterAtomReturn } from './accessesFeature/groupsAtom';
import { SelectAllAndNew } from './SelectAllAndNew';

export const TeamClusterGroup: FC<{
  loading: boolean;
  atoms: TClusterAtomReturn;
  failedValidationIds?: string[];
}> = ({ loading, atoms }) => {
  const { t } = useTranslation();

  const currentUser = useUser();

  const clusters = useAtomValue(atoms.dataVisible);

  const select = useSetAtom(atoms.select);
  const invert = useSetAtom(atoms.invert);
  const clear = useSetAtom(atoms.clear);
  const selectAllAndNew = useSetAtom(atoms.selectAllAndNew as WritableAtom<null, unknown[], void>);

  const renderAdditional = useCallback(
    (item: IGroupItem<{ selectAndAllParent: boolean }>, hovered: boolean) => {
      if (canSelectAndAllCluster(currentUser, item.id)) {
        return (
          <SelectAllAndNew
            item={item}
            selectAllAndNew={selectAllAndNew}
            hovered={hovered}
            selectText={t('base.selectAllAndNewClusters')}
            unselectText={t('base.excludeClusters')}
          />
        );
      } else {
        return null;
      }
    },
    [selectAllAndNew],
  );

  return (
    <Group
      title={t('accounting.clusters')}
      items={clusters}
      onSelect={select}
      onClear={clear}
      onInvert={invert}
      additionalRendered={renderAdditional}
      loading={loading}
    />
  );
};

export default TeamClusterGroup;
