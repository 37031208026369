import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {
  GridCell,
  GridColumnMenu,
  useGridApiRef,
  type GridRenderCellParams,
  type GridRowSelectionModel,
} from '@mui/x-data-grid';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  DataGridBox,
  DataGridPagination,
  dataGridRowsPerPageAtom,
  DataGridToolbar,
  DefaultDataGrid,
  IconButton,
  menuCellAtom,
  TableContextMenu,
  Tooltip,
} from 'components';
import { useLoading } from 'hooks';
import { useComputedGridHeight } from 'hooks/useComputedGridHeight';
import { EUserStatus } from 'hooks/useUser';
import { ERoutes } from 'routing';
import { atomWithAuthReset, densityMap } from 'utils';
import TeamMembersBulkActions from './TeamMembersBulkActions';

interface IProps {
  data: object[];
  selected: GridRowSelectionModel;
  setSelected: (selected: GridRowSelectionModel) => void;
  onActivate: (usersIds?: string[]) => void;
  onDeactive: (usersIds?: string[]) => void;
  onDelete: (usersIds?: string[]) => void;
}

export const statusTranslateKeyMap = {
  [EUserStatus.ACTIVE]: 'team.active',
  [EUserStatus.INACTIVE]: 'team.inactive',
  [EUserStatus.PENDING]: 'team.pending',
};

const pageAtom = atomWithAuthReset(0);

export const TeamMembersTable = ({ data, selected, setSelected, onActivate, onDeactive, onDelete }: IProps) => {
  const apiRef = useGridApiRef();
  const { i18n, t } = useTranslation();
  const { loading } = useLoading();
  const setMenuCell = useSetAtom(menuCellAtom);
  const pageSize = useAtomValue(dataGridRowsPerPageAtom);
  const navigate = useNavigate();
  const [page, setPage] = useAtom(pageAtom);
  const navigateToUser = useCallback(
    (id: string, newTab?: boolean) => {
      const path = `${ERoutes.ManagementTeamMembersDetails.replace(':id', id)}`;
      newTab ? window.open(path, '_blank') : navigate(path);
    },
    [navigate],
  );

  const { height } = useComputedGridHeight({ apiRef });

  const columns = useMemo(
    () => [
      { field: 'email', headerName: t('team.email') },
      { field: 'role', headerName: t('team.role') },
      { field: 'status', headerName: t('team.status') },
      { field: 'createdAt', headerName: t('team.createdAt') },
      { field: 'lastActivity', headerName: t('team.lastActivity') },
      {
        field: 'action',
        sortable: false,
        headerName: t('playerBrowser.action'),
        renderCell: (props: GridRenderCellParams) => (
          <>
            {(
              [
                {
                  onClick: () => navigateToUser(props.row.id),
                  IconComponent: EditIcon,
                  key: 'edit',
                  color: 'inherit',
                  visible: true,
                },
                {
                  onClick: () => onActivate([props.row.id]),
                  IconComponent: PersonIcon,
                  key: 'activate',
                  color: 'success',
                  visible: props.row.status === t(statusTranslateKeyMap[EUserStatus.INACTIVE]),
                },
                {
                  onClick: () => onDeactive([props.row.id]),
                  IconComponent: PersonOffIcon,
                  key: 'deactivate',
                  color: 'info',
                  visible: props.row.status === t(statusTranslateKeyMap[EUserStatus.ACTIVE]),
                },
                {
                  onClick: () => onDelete([props.row.id]),
                  IconComponent: DeleteIcon,
                  key: 'delete',
                  color: 'error',
                  visible: true,
                },
              ] as const
            ).map(
              ({ onClick, IconComponent, key, color, visible }) =>
                visible && (
                  <Tooltip key={key} title={t('team.' + key)}>
                    <IconButton
                      color={color}
                      size={densityMap[apiRef.current?.state.density] || 'medium'}
                      onClick={onClick}
                    >
                      <IconComponent fontSize={densityMap[apiRef.current?.state.density] || 'medium'} />
                    </IconButton>
                  </Tooltip>
                ),
            )}
          </>
        ),
      },
    ],
    [i18n.language, t],
  );

  return (
    <DataGridBox height={height}>
      <DefaultDataGrid
        rows={data}
        columns={columns}
        paginationModel={{ page, pageSize }}
        apiRef={apiRef}
        loading={loading}
        sortingMode="client"
        checkboxSelection
        rowSelectionModel={selected}
        onRowSelectionModelChange={setSelected}
        slotProps={{
          baseCheckbox: { color: 'default' },
        }}
        slots={{
          toolbar: () => <DataGridToolbar localStorageKey="management-team" />,
          pagination: () => <DataGridPagination count={data.length} page={page} onPageChange={setPage} />,
          columnMenu: (props) => (
            <GridColumnMenu
              {...props}
              slots={{
                columnMenuColumnsItem:
                  props.colDef.field === 'action'
                    ? () => (
                        <TeamMembersBulkActions onActivate={onActivate} onDeactive={onDeactive} onDelete={onDelete} />
                      )
                    : null,
              }}
            />
          ),
          cell: (props) => {
            return (
              <GridCell
                {...props}
                onContextMenu={(evt: MouseEvent) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  if (props.column.field === 'action' || props.column.field === '__check__') {
                    return;
                  }
                  setMenuCell({ rowId: props.rowId, column: props.column });
                }}
              />
            );
          },
        }}
      />
      <TableContextMenu apiRef={apiRef} navigateToDetails={navigateToUser} detailsFieldId="id" />
    </DataGridBox>
  );
};

export default TeamMembersTable;
