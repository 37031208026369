import { useCallback, useEffect, type MutableRefObject } from 'react';
import { type GridApiCommunity } from '@mui/x-data-grid/internals';
import { useStore } from 'jotai';
import { leftMenuOpenedAtom } from 'hooks';
import { getCanvasFont, getComputedStyle, measureText } from 'utils';
import { useUserPreferences } from './useUserPreferences';

const LOCAL_STORAGE_KEY_PREFIX = 'x-data-grid-columns-width--';
const AUTO_WIDTH_MAX_CHARACTERS = 40;

// This hook many time is mounted & unmounted (data grid :))
// Because of these mounts/unmounts and setTimeout, function adjustColumnsWidth is losing context to newest additionalColumnElements
const additionalColumnElementsRef: Record<string, Record<string, string> | undefined> = {};

export const useSaveDataGridColumnsWidth = (
  localStorageKey: string,
  apiRef: MutableRefObject<GridApiCommunity>,
  additionalColumnElements?: Record<string, string>,
) => {
  const { userPreferences, setUserPreference } = useUserPreferences();
  const store = useStore();
  additionalColumnElementsRef[localStorageKey] = additionalColumnElements;
  const adjustColumnsWidth = useCallback(() => {
    const columns = apiRef.current.getVisibleColumns();
    const rowIds = apiRef.current.getAllRowIds();
    const widths: Record<string, number> = {};
    let cell: HTMLElement | null = null;

    for (const c of columns) {
      if (c.field === '__check__') {
        continue;
      }
      cell = apiRef.current.getColumnHeaderElement(c.field);
      for (const r of rowIds) {
        cell = apiRef.current.getCellElement(r, c.field);
        if (cell) {
          break;
        }
      }
      if (cell) {
        break;
      }
    }
    if (!cell) {
      return;
    }

    const paddingLeft = Number(getComputedStyle(cell, 'padding-left').replace('px', '')) || 0;
    const paddingRight = Number(getComputedStyle(cell, 'padding-right').replace('px', '')) || 0;
    const font = getCanvasFont(cell || document.body);
    columns.forEach((column) => {
      if (column.field === '__check__') {
        apiRef.current.setColumnWidth(column.field, 20);
        return;
      }
      widths[column.field] = measureText(column.headerName || '', font).width;
      const additionalColumnElement = additionalColumnElementsRef[localStorageKey]?.[column.field];
      if (additionalColumnElement) {
        widths[column.field] = Math.max(widths[column.field], measureText(additionalColumnElement, font).width);
      }
      rowIds.forEach((rowId) => {
        const cellValue = apiRef.current.getCellValue(rowId, column.field);
        if (typeof cellValue === 'string') {
          widths[column.field] = Math.max(
            widths[column.field],
            measureText(cellValue.slice(0, AUTO_WIDTH_MAX_CHARACTERS), font).width,
          );
        }
      });
      widths[column.field] += paddingLeft + paddingRight + 10;
    });
    const innerWidth = apiRef.current.getRootDimensions().viewportInnerSize.width;
    const columnsWidth = Object.values(widths).reduce((acc, width) => acc + width, 0);
    // -5 to avoid horizontal scroll
    const additionalColumnWidth = innerWidth > columnsWidth ? (innerWidth - columnsWidth) / columns.length - 5 : 0;

    columns.forEach((column) => {
      apiRef.current.setColumnWidth(column.field, widths[column.field] + additionalColumnWidth);
    });
  }, []);

  useEffect(() => {
    if (userPreferences.autoAdjustColumnsWidth) {
      let timeoutId: NodeJS.Timeout;
      const onWidowResize = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(adjustColumnsWidth, 250);
      };
      window.addEventListener('resize', onWidowResize);
      const unsubMenuToggle = store.sub(leftMenuOpenedAtom, () => setTimeout(adjustColumnsWidth, 250));
      const unsubColumnVisibility = apiRef.current.subscribeEvent('columnVisibilityModelChange', adjustColumnsWidth);
      const unsubRowUpdate = apiRef.current.subscribeEvent('rowsSet', () => {
        // I didin't find better way to fire adjustColumnsWidth after all rows are rendered
        // event renderedRowsIntervalChange doesn't work for this purpose
        setTimeout(adjustColumnsWidth, 40);
      });

      return () => {
        unsubRowUpdate();
        unsubMenuToggle();
        unsubColumnVisibility();
        window.removeEventListener('resize', onWidowResize);
        clearTimeout(timeoutId);
      };
    }
  }, [userPreferences.autoAdjustColumnsWidth]);

  useEffect(() => {
    const saveColumnsWidth = () => {
      const columns = apiRef.current.getAllColumns();
      const dataToSave = columns.reduce(
        (acc, column) => {
          acc[column.field] = Number(column.width);
          return acc;
        },
        {} as Record<string, number>,
      );
      localStorage.setItem(LOCAL_STORAGE_KEY_PREFIX + localStorageKey, JSON.stringify(dataToSave));
    };

    try {
      const columnsWidthJson = localStorage.getItem(LOCAL_STORAGE_KEY_PREFIX + localStorageKey);
      if (columnsWidthJson) {
        const parsedColumnsWidth = JSON.parse(columnsWidthJson);
        const columns = apiRef.current.getAllColumns();
        columns.forEach((column) => {
          const savedWidth = parsedColumnsWidth?.[column.field];
          if (savedWidth && typeof savedWidth === 'number') {
            apiRef.current.setColumnWidth(column.field, savedWidth);
          }
        });
      } else if (userPreferences.autoAdjustColumnsWidth) {
        adjustColumnsWidth();
      }
    } catch (err) {
      console.error(err);
    }

    const unsubColumnResize = apiRef.current.subscribeEvent('columnResizeStop', () =>
      setUserPreference('autoAdjustColumnsWidth', false),
    );
    const unsubColumnWidthChange = apiRef.current.subscribeEvent('columnWidthChange', saveColumnsWidth);

    return () => {
      unsubColumnResize();
      unsubColumnWidthChange();
    };
  }, []);

  return { adjustColumnsWidth };
};
