import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGridApiRef } from '@mui/x-data-grid';
import { DataGridBox, DataGridToolbar, DefaultDataGrid } from 'components';
import { ENormalizeGroups, useAccountingData, useLoading, usePerms } from 'hooks';
import { useComputedGridHeight } from 'hooks/useComputedGridHeight';
import AccountingReportExport from './AccountingReportExport';
import AccountingReportFooter from './AccountingReportFooter';
import { generateColumns } from './generateAccountingReportTableColumns';

export const AccountingReportTable = () => {
  const { i18n, t } = useTranslation();
  const apiRef = useGridApiRef();
  const { data, totalDataCount, page, setPage, setSort, sort, summary, groupBy } = useAccountingData();
  const { loading } = useLoading();
  const perms = usePerms();
  const columns = useMemo(() => generateColumns(t, groupBy, perms), [i18n.language, groupBy, perms]);
  const [summaryWithTitle, setSummaryWithTitle] = useState<Record<string, string> | undefined>(undefined);

  const { height } = useComputedGridHeight({ apiRef, additionalRowCount: 1 });

  useEffect(() => {
    const addTitleToSummary = () => {
      const firstColumnField = apiRef.current?.getVisibleColumns?.()[0]?.field;
      setSummaryWithTitle(
        firstColumnField
          ? { [firstColumnField]: t('accounting.summary'), ...summary }
          : (summary as unknown as Record<string, string>),
      );
    };

    addTitleToSummary();
    return apiRef.current.subscribeEvent('columnVisibilityModelChange', addTitleToSummary);
  }, [summary]);

  return (
    <DataGridBox height={height}>
      <DefaultDataGrid
        rows={data}
        columns={columns}
        apiRef={apiRef}
        loading={loading}
        sortModel={sort}
        onSortModelChange={setSort}
        getCellClassName={(params) => {
          if (params.field === 'ggr' && params.value !== '0.00') {
            return params.value[0] === '-' ? 'ggr-negative' : 'ggr-positive';
          }
          return '';
        }}
        sx={{
          '& .ggr-positive': {
            color: 'success.main',
          },
          '& .ggr-negative': {
            color: 'error.main',
          },
        }}
        slots={{
          toolbar: () => (
            <DataGridToolbar
              localStorageKey="accounting-report"
              additionalColumnElements={summaryWithTitle}
              disableManageColumns={groupBy !== ENormalizeGroups.NONE}
              manageColumnsTooltip={
                groupBy !== ENormalizeGroups.NONE ? t('accounting.manageColumnsDisabledTooltip') : undefined
              }
            >
              <AccountingReportExport sort={sort} />
            </DataGridToolbar>
          ),
          footer: () => (
            <AccountingReportFooter
              summary={summaryWithTitle}
              totalDataCount={totalDataCount}
              page={page}
              setPage={setPage}
            />
          ),
        }}
      />
    </DataGridBox>
  );
};

export default AccountingReportTable;
