import { ContainerMenu } from './ContainerMenu';
import { type IMenuItem } from './generateMenuData';
import { MenuItem, type ISideMenuItem } from './MenuItem';
import { useMenuItems } from './useMenuItems';

const mapItemToMenuProps = (item: IMenuItem): ISideMenuItem => {
  return {
    iconComponent: item.icon,
    tooltipTitle: item.key,
    subItems: item.groups.map((group) => {
      return {
        subItemTitle: group.key,
        elements: group.routes.map((route) => {
          return {
            elementTitle: route.key,
            route: route.route,
          };
        }),
      };
    }),
  };
};

export const AlternativeMenu = () => {
  const { menuItems } = useMenuItems();

  return (
    <ContainerMenu>
      {menuItems.map((menuItem) => {
        return <MenuItem {...mapItemToMenuProps(menuItem)} key={menuItem.key} />;
      })}
    </ContainerMenu>
  );
};

export default AlternativeMenu;
