import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridCell, useGridApiRef, type GridRenderCellParams, type GridSortModel } from '@mui/x-data-grid';
import { useSetAtom } from 'jotai';
import {
  DataGridBox,
  DataGridPagination,
  DataGridToolbar,
  DefaultDataGrid,
  IconButton,
  menuCellAtom,
  TableContextMenu,
} from 'components';
import { useLoading, usePerms } from 'hooks';
import { useComputedGridHeight } from 'hooks/useComputedGridHeight';
import { ERoutes } from 'routing';
import { generateColumns } from './playerBrowserGenerateColumns';

const densityMap: Record<string, 'small' | 'medium' | 'large'> = {
  compact: 'small',
  standard: 'medium',
  comfort: 'large',
};

interface IProps {
  page: number;
  setPage: (page: number) => void;
  setSort: (sort: GridSortModel) => void;
  sort: GridSortModel;
  data: object[];
  totalCount: number;
}

export const PlayerBrowserTable = ({ setSort, page, setPage, data, totalCount, sort }: IProps) => {
  const apiRef = useGridApiRef();
  const perms = usePerms();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { loading } = useLoading();
  const setMenuCell = useSetAtom(menuCellAtom);
  const navigateToPlayer = useCallback(
    (id: string, newTab?: boolean) => {
      const queryParams = new URLSearchParams(window.location.search);
      const path = `${ERoutes.PlayerBrowserDetails.replace(':id', id)}?${queryParams.toString()}`;
      newTab ? window.open(path, '_blank') : navigate(path);
    },
    [navigate],
  );

  const { height } = useComputedGridHeight({ apiRef });

  const columns = useMemo(
    () => [
      ...generateColumns(t, perms),
      {
        field: 'action',
        disableColumnMenu: true,
        sortable: false,
        headerName: t('playerBrowser.action'),
        renderCell: (props: GridRenderCellParams) => (
          <IconButton
            size={densityMap[apiRef.current?.state.density] || 'medium'}
            onClick={() => navigateToPlayer(props.row.playerId)}
          >
            <VisibilityIcon fontSize={densityMap[apiRef.current?.state.density] || 'medium'} />
          </IconButton>
        ),
      },
    ],
    [i18n.language, perms],
  );

  return (
    <DataGridBox height={height}>
      <DefaultDataGrid
        rows={data}
        columns={columns}
        apiRef={apiRef}
        loading={loading}
        sortModel={sort}
        onSortModelChange={setSort}
        slots={{
          toolbar: () => (
            <DataGridToolbar
              localStorageKey="player-browser"
              defaultHideColumns={['tester', 'firstTransactionDate', 'playerId', 'playerInternalId']}
            />
          ),
          pagination: () => <DataGridPagination count={totalCount} page={page} onPageChange={setPage} />,
          cell: (props) => {
            return (
              <GridCell
                {...props}
                onContextMenu={(evt: MouseEvent) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  if (props.column.field === 'action') {
                    return;
                  }
                  setMenuCell({ rowId: props.rowId, column: props.column });
                }}
              />
            );
          },
        }}
      />
      <TableContextMenu apiRef={apiRef} navigateToDetails={navigateToPlayer} detailsFieldId="playerId" />
    </DataGridBox>
  );
};

export default PlayerBrowserTable;
