import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import dayjs from 'dayjs';
import {
  Box,
  DateTimeRangePicker,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TimezoneSelector,
  Typography,
} from 'components';
import { useAccountingFiltersDate } from 'hooks/useAccountingFilters';

export const AccountingReportFilterDateRange = () => {
  const { t } = useTranslation();
  const date = useAccountingFiltersDate();
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const dateFromText = useMemo(() => dayjs(date.from).format('YYYY/MM/DD'), [date.from]);
  const timeFromText = useMemo(() => dayjs(date.from).format('HH:mm:ss'), [date.from]);
  const dateToText = useMemo(() => dayjs(date.to).format('YYYY/MM/DD'), [date.to]);
  const timeToText = useMemo(() => dayjs(date.to).format('HH:mm:ss'), [date.to]);

  const onAcceptDateRange = useCallback(
    (from: string, to: string) => {
      date.setFrom(from);
      date.setTo(to);
    },
    [date],
  );

  return (
    <>
      <DateTimeRangePicker
        open={openDatePicker}
        close={() => setOpenDatePicker(false)}
        onAccept={onAcceptDateRange}
        initFrom={date.from}
        initTo={date.to}
        zone={date.zone}
      />
      <TimezoneSelector value={date.zone} onChange={date.setZone} />
      <FormControl>
        <InputLabel>{t('accounting.DateRange')}</InputLabel>
        <OutlinedInput
          readOnly
          label={t('accounting.DateRange')}
          onClick={() => setOpenDatePicker(true)}
          sx={{ cursor: 'pointer' }}
          startAdornment={
            <InputAdornment position="start" sx={{ pt: 4.5, pb: 4 }}>
              <Box
                sx={{
                  cursor: 'pointer',
                  color: 'text.primary',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton size="small">
                    <CalendarTodayIcon fontSize="small" />
                  </IconButton>
                  <DateRangeInputRow dateText={dateFromText} timeText={timeFromText} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton size="small">
                    <CalendarTodayIcon fontSize="small" />
                  </IconButton>
                  <DateRangeInputRow dateText={dateToText} timeText={timeToText} />
                </Box>
              </Box>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  );
};

const DateRangeInputRow = ({ dateText, timeText }: { dateText: string; timeText: string }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
      <Typography sx={{ marginLeft: 0.5 }}>{dateText}</Typography>
      <Typography sx={{ marginLeft: 1 }} variant="body2">
        {timeText}
      </Typography>
    </Box>
  );
};

export default AccountingReportFilterDateRange;
