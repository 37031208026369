import { permsAtom } from 'auth';
import { useAtomValue } from 'jotai';
import { type IScope } from 'views/TeamMembersUser/accessesFeature/permissionMapping';
import { EUserRole, type IUser } from './useUser';

function getUniqueIds(scopes: IScope[], key: keyof IScope) {
  return [...new Set(scopes.map((scope) => scope[key]))];
}

function anyClusterHasSelectAll(scopes: IScope[]) {
  return scopes.filter((scope) => scope.clusterId && !scope.clientId && !scope.operatorId).length > 0;
}

function anyClientHasSelectAll(scopes: IScope[]) {
  return scopes.filter((scope) => scope.clientId && !scope.operatorId).length > 0;
}

export const parsePerms = (user: IUser) => {
  const arr = user.permissions;

  return {
    loaded: true,
    reportsAccounting: arr.includes('view_report_transactions'),
    reportsPlayerBrowser: arr.includes('view_combined_transactions_by_players'),
    reportsAccountingExport: arr.includes('export_report_transactions_csv'),
    team: arr.includes('view_users'),
    clusters: user.role === EUserRole.ADMIN || getUniqueIds(user.scopes, 'clusterId').length > 1,
    clients:
      user.role === EUserRole.ADMIN ||
      getUniqueIds(user.scopes, 'clientId').length > 1 ||
      anyClusterHasSelectAll(user.scopes),
    operators:
      user.role === EUserRole.ADMIN ||
      getUniqueIds(user.scopes, 'operatorId').length > 1 ||
      anyClientHasSelectAll(user.scopes) ||
      anyClusterHasSelectAll(user.scopes),
    reports: true,
    reportsGameBrowser: true,
  };
};

export const usePerms = () => {
  const perms = useAtomValue(permsAtom);
  return perms;
};
