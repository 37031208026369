import { gql } from '@apollo/client';
import { t } from 'i18next';
import { parseCountryCode, parseCurrencyNumber, parseDateStringTz } from 'utils';

interface IRawRecord {
  clusterLabel?: string;
  clientLabel?: string;
  creditAmount: number;
  currency: string;
  debitAmount: number;
  entityId: string;
  entityType: string;
  operatorLabel: string;
  playerExternalId: string;
  playerId: string;
  playerInternalId: string;
  slotLabel: string;
  tester: boolean;
  createdAt: string;
  country: string;
  historyViewerUrl: string;
}

export interface IPlayerBrowserDetailsRawData {
  bets: { items: IRawRecord[]; skip: number; take: number; totalCount: number };
}

export const GET_PLAYER = gql`
  query GetPlayer($where: JSONObject, $skip: Int, $take: Int, $orderBy: String, $orderDirection: String) {
    bets(filter: $where, take: $take, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection) {
      totalCount
      skip
      take
      items {
        slotLabel
        playerId
        playerInternalId
        playerExternalId
        debitAmount
        creditAmount
        operatorLabel
        clusterLabel
        clientLabel
        entityId
        entityType
        currency
        createdAt
        country
        historyViewerUrl
      }
    }
  }
`;

export const parsePlayerBrowserDetailsData = (
  rawData: IPlayerBrowserDetailsRawData,
  lang: string,
  timezone: string,
) => {
  return {
    totalCount: rawData.bets.totalCount,
    data: rawData.bets.items.map((item) => {
      return {
        playerId: item.playerId,
        playerInternalId: item.playerInternalId,
        playerExternalId: item.playerExternalId,
        clusterLabel: item.clusterLabel,
        operatorLabel: item.operatorLabel,
        clientLabel: item.clientLabel,
        country: parseCountryCode(item.country, lang),
        historyViewerUrl: item.historyViewerUrl,
        //
        slotLabel: item.slotLabel,
        id: item.entityId,
        entityId: item.entityId,
        currency: item.currency,
        debitAmount: parseCurrencyNumber(item.debitAmount),
        creditAmount: parseCurrencyNumber(item.creditAmount),
        createdAt: parseDateStringTz(item.createdAt, timezone),
        entityType: item.entityType === 'BONUS' ? t('playerBrowser.bonusRound') : t('playerBrowser.betRound'),
      };
    }),
  };
};
