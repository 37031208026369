import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs, { type Dayjs } from 'dayjs';
import { useAtom, type PrimitiveAtom } from 'jotai';
import {
  Box,
  Button,
  LogicOperatorButton,
  PopperMenu,
  PopperSelect,
  Typography,
  type ILogicOperator,
} from 'components';

type Operator = 'greater' | 'less' | 'between';

export interface IDateButtonInputState {
  value: string;
  value2: string;
  operator: Operator;
}

interface IProps {
  atom: PrimitiveAtom<IDateButtonInputState>;
  label: string;
  onToggle?: (open: boolean) => void;
  disabled?: boolean;
}

enum EErrors {
  INVALID,
  BETWEEN,
}

export const DateButtonInput = ({ label, onToggle, atom, disabled }: IProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [state, setState] = useAtom(atom);

  const initDate = useMemo(() => dayjs().subtract(30, 'minute'), []);
  const maxDate = useMemo(() => dayjs(), []);
  const minDate = useMemo(() => dayjs().subtract(1, 'year'), []);

  const [inputValue, setInputValue] = useState<Dayjs | null>(initDate);
  const [inputValue2, setInputValue2] = useState<Dayjs | null>(initDate);
  const [tempOperator, setTempOperator] = useState<Operator>('greater');
  const [error, setError] = useState<EErrors | null>(null);

  const [firstPickerOpened, setFirstPickerOpened] = useState(false);
  const [secondPickerOpened, setSecondPickerOpened] = useState(false);

  const logicOperators = [
    { sign: '>', label: t('inputButtons.after'), key: 'greater' },
    { sign: '<', label: t('inputButtons.before'), key: 'less' },
    { sign: '∈', label: t('inputButtons.between'), key: 'between' },
  ] as ILogicOperator<Operator>[];
  const buttonRef = useRef<HTMLButtonElement>(null);

  const buttons = useMemo(
    () => [
      { key: 'inputButtons.Last30Minutes', func: () => setInputValue(dayjs().subtract(30, 'minute')) },
      { key: 'inputButtons.LastHour', func: () => setInputValue(dayjs().subtract(1, 'hour')) },
      { key: 'inputButtons.Last6Hours', func: () => setInputValue(dayjs().subtract(6, 'hour')) },
      { key: 'inputButtons.Last24Hours', func: () => setInputValue(dayjs().subtract(24, 'hour')) },
      { key: 'inputButtons.Last7Days', func: () => setInputValue(dayjs().subtract(7, 'day')) },
    ],
    [],
  );

  useEffect(() => {
    if (open) {
      state.value && setInputValue(dayjs(state.value));
      state.value2 && setInputValue2(dayjs(state.value2));
      setTempOperator(state.operator);
    } else {
      setError(null);
    }
    onToggle?.(open);
  }, [open, onToggle]);

  useEffect(() => {
    setInputValue2(tempOperator === 'between' ? inputValue?.clone() || null : dayjs(state.value2));
  }, [tempOperator]);

  useEffect(() => {
    error && setError(null);
  }, [inputValue, inputValue2]);

  const handleAccept = useCallback(() => {
    const formattedValue = inputValue?.format();
    if (!formattedValue || formattedValue === 'Invalid Date') {
      setError(EErrors.INVALID);
      return;
    }

    if (tempOperator === 'between') {
      const formattedValue2 = inputValue2?.format();
      if (!formattedValue2 || formattedValue2 === 'Invalid Date') {
        setError(EErrors.INVALID);
        return;
      }
      if (!inputValue?.isBefore(inputValue2)) {
        setError(EErrors.BETWEEN);
        return;
      }
      setState((prevState) => ({
        ...prevState,
        value: formattedValue,
        operator: tempOperator,
        value2: formattedValue2,
      }));
    } else {
      setState((prevState) => ({ ...prevState, value: formattedValue, operator: tempOperator }));
    }
    setOpen(false);
  }, [inputValue, inputValue2]);

  const handleClear = useCallback(() => {
    setState((prevState) => ({ ...prevState, value: '', value2: '', operator: 'greater' }));
    setOpen(false);
  }, []);

  const handleLogicOperatorButtonChange = useCallback(
    (operator: Operator) => {
      setState((prevState) => {
        if (operator === 'between') {
          return { ...prevState, operator, value2: prevState.value };
        }
        return { ...prevState, operator };
      });
    },
    [state.value, state.operator],
  );

  return (
    <>
      <Button
        ref={buttonRef}
        disabled={disabled}
        variant="outlined"
        sx={{
          textTransform: 'none',
          color: state.value ? 'main.primary' : 'text.primary',
          paddingLeft: 1,
          paddingRight: 1,
        }}
        onClick={() => setOpen(true)}
      >
        {label}
        {state.value && (
          <>
            <LogicOperatorButton
              operators={logicOperators}
              value={state.operator}
              onChange={handleLogicOperatorButtonChange}
            />
            {state.operator === 'between'
              ? `[${dayjs(state.value).format('YY/MM/DD HH:mm:ss')}, ${dayjs(state.value2).format('YY/MM/DD HH:mm:ss')}]`
              : dayjs(state.value).format('YY/MM/DD HH:mm:ss')}
          </>
        )}
        {open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ marginLeft: 1 }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ marginLeft: 1 }} />
        )}
      </Button>

      <PopperMenu
        open={open}
        anchorEl={buttonRef.current}
        onClose={() => setOpen(false)}
        placement="bottom-start"
        paperProps={{ sx: { padding: 1, display: 'flex', flexDirection: 'column', gap: 1, width: 300 } }}
        dontCloseOnOutsideClick={firstPickerOpened || secondPickerOpened}
      >
        <PopperSelect
          value={tempOperator}
          options={logicOperators.map((operator) => ({
            key: operator.key,
            label: `${label} ${operator.sign} (${operator.label})`,
          }))}
          onChange={(value) => setTempOperator(value as Operator)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
            ampm={false}
            maxDate={maxDate}
            minDate={minDate}
            slotProps={{
              textField: { size: 'small', fullWidth: true },
            }}
            value={inputValue}
            format="YYYY/MM/DD HH:mm:ss"
            onChange={setInputValue}
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            open={firstPickerOpened}
            onOpen={() => setFirstPickerOpened(true)}
            onClose={() => setFirstPickerOpened(false)}
          />

          {tempOperator === 'between' && (
            <MobileDateTimePicker
              ampm={false}
              maxDate={maxDate}
              minDate={minDate}
              slotProps={{ textField: { size: 'small', fullWidth: true } }}
              value={inputValue2}
              format="YYYY/MM/DD HH:mm:ss"
              onChange={setInputValue2}
              views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
              open={secondPickerOpened}
              onOpen={() => setSecondPickerOpened(true)}
              onClose={() => setSecondPickerOpened(false)}
            />
          )}
        </LocalizationProvider>
        {error !== null && (
          <Typography color="error" variant="caption">
            {error === EErrors.BETWEEN ? t('inputButtons.dateBetweenError') : t('inputButtons.InvalidDate')}
          </Typography>
        )}
        {buttons.map(({ key, func }) => (
          <Button
            key={key}
            variant="outlined"
            size="small"
            onClick={() => {
              setTempOperator('greater');
              func();
            }}
          >
            {t(key)}
          </Button>
        ))}
        <Box sx={{ alignSelf: 'flex-end', display: 'flex', gap: 1 }}>
          {state.value && (
            <Button onClick={handleClear} size="small">
              {t('base.clear')}
            </Button>
          )}
          <Button onClick={handleAccept} size="small">
            {t('base.accept')}
          </Button>
        </Box>
      </PopperMenu>
    </>
  );
};

export default DateButtonInput;
