import { atom } from 'jotai';
import type { EFilterTesters, ENormalizeGroups, ENormalizePossibleCurrencies } from 'hooks/useAccountingFilters/types';

export interface IAppliedFilters {
  clusters: string[];
  clients: string[];
  operators: string[];
  games: string[];
  currencies: string[];
  countries: string[];
  dateFrom: string;
  dateTo: string;
  timezone: string;
  normalizeEnabled: boolean;
  normalizeCurrency: ENormalizePossibleCurrencies;
  normalizeGroupBy: ENormalizeGroups;
  currencyDate: string;
  players: EFilterTesters;
}

export const appliedFiltersAtom = atom<IAppliedFilters | null>(null);
