import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Perms } from 'auth';
import { Box, ButtonInputForm, type IButtonInputsFormAppliedFilter, type IButtonInputsFormInputType } from 'components';
import { EFilterTesters, useButtonInputsFormQueryParams, usePerms, type IDefaultFiltersData } from 'hooks';
import { mapButtonInputsToCurrency } from 'utils';

const generateInputsData = (perms: Perms) => {
  const data = [
    { type: 'multiselect', key: 'games', translateKey: 'playerBrowser.games', order: 4 },
    { type: 'uuid', key: 'playerId', translateKey: 'playerBrowser.playerId' },
    { type: 'string', key: 'playerExternalId', translateKey: 'playerBrowser.playerExternalId' },
    { type: 'uuid', key: 'playerInternalId', translateKey: 'playerBrowser.playerInternalId' },
    { type: 'uuid', key: 'betId', translateKey: 'playerBrowser.betId' },
    { type: 'number', key: 'bet', translateKey: 'playerBrowser.betValue' },
    { type: 'number', key: 'win', translateKey: 'playerBrowser.winValue' },
    { type: 'date', key: 'betTime', translateKey: 'playerBrowser.betTime' },
    { type: 'multiselect', key: 'currencies', translateKey: 'playerBrowser.currencies' },
    { type: 'multiselect', key: 'countries', translateKey: 'playerBrowser.countries' },
    { type: 'multiselect', key: 'entityType', translateKey: 'playerBrowser.betType' },
    { type: 'select', key: 'tester', translateKey: 'accounting.Players' },
    perms.operators && { type: 'multiselect', key: 'operators', translateKey: 'playerBrowser.operators', order: 3 },
    perms.clients && { type: 'multiselect', key: 'clients', translateKey: 'playerBrowser.clients', order: 2 },
    perms.clusters && { type: 'multiselect', key: 'clusters', translateKey: 'playerBrowser.clusters', order: 1 },
  ].filter(Boolean) as { type: IButtonInputsFormInputType; key: string; translateKey: string; order?: number }[];

  return data.sort((a, b) => {
    if (!a.order) {
      return 1;
    }
    if (!b.order) {
      return -1;
    }

    return a.order - b.order;
  });
};

const initialShowingInputs = [
  'clusters',
  'clients',
  'operators',
  'games',
  'playerExternalId',
  'betId',
  'bet',
  'win',
  'betTime',
];

interface IProps {
  onAccept: (filters: IButtonInputsFormAppliedFilter[]) => void;
  filtersData: IDefaultFiltersData | null;
  loading: boolean;
}

export const PlayerBrowserFilters = ({ onAccept, loading, filtersData }: IProps) => {
  const { setQueryParams, initialState } = useButtonInputsFormQueryParams(window.location.search);
  const [showingInputs, setShowingInputs] = useState(() => {
    return [...initialShowingInputs, ...Object.keys(initialState).filter((key) => !initialShowingInputs.includes(key))];
  });
  const { t } = useTranslation();
  const perms = usePerms();
  const inputsData = useMemo(() => generateInputsData(perms), [perms]);

  const handleAccept = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    const mappedFilters = mapButtonInputsToCurrency(filters, ['bet', 'win']);
    setQueryParams(mappedFilters);
    onAccept(mappedFilters);
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
      <ButtonInputForm
        inputsData={inputsData}
        showingInputs={showingInputs}
        setShowingInputs={setShowingInputs}
        initialState={initialState}
        loading={loading}
        selectOptions={{
          ...filtersData,
          entityType: [
            { name: t('playerBrowser.regularBet'), id: 'BET' },
            { name: t('playerBrowser.bonusBet'), id: 'BONUS' },
          ],
          tester: [
            { name: t('accounting.testersPlayersOnly'), id: EFilterTesters.TESTERS_ONLY },
            { name: t('accounting.realPlayersOnly'), id: EFilterTesters.REAL_ONLY },
            { name: t('accounting.allPlayers'), id: EFilterTesters.ALL },
          ],
        }}
        onAccept={handleAccept}
      />
    </Box>
  );
};

export default PlayerBrowserFilters;
