import { useMemo, useState, type FC, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { ArrowDropDown, ChevronRight } from '@mui/icons-material';
import { Box, Collapse, Fade, MenuItem as MUIMenuItem, Typography } from '@mui/material';
import { useSidemenu } from 'hooks/useSideMenu';

export interface ISideMenuItem {
  tooltipTitle: string;
  iconComponent: ReactNode;
  subItems: ISideMenuSubItem[];
}

export interface ISideMenuSubItem {
  subItemTitle: string;
  elements: ISideMenuSubItemElements[];
}

interface ISideMenuSubItemElements {
  elementTitle: string;
  route: string;
}

const isActiveWithin = (items: ISideMenuSubItem[], pathname: string) => {
  for (const item of items) {
    for (const element of item.elements) {
      if (pathname === element.route) {
        return true;
      }
    }
  }

  return false;
};

export const MenuItem: FC<ISideMenuItem> = ({ tooltipTitle, iconComponent, subItems }) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { menuOpen } = useSidemenu();

  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => setExpanded((expanded) => !expanded);

  const shouldHighlight = useMemo(() => {
    const hasActiveItem = isActiveWithin(subItems, pathname);
    return hasActiveItem && !menuOpen;
  }, [pathname, menuOpen]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, pl: 0.5 }}>
      <MUIMenuItem
        onClick={toggleExpanded}
        selected={shouldHighlight}
        sx={{
          transition: 'background-color 0.3s',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, width: '100%' }}>
          {iconComponent}

          <Fade in={menuOpen}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              {t(tooltipTitle)}

              <ArrowDropDown
                sx={(theme) => ({
                  marginLeft: 'auto',
                  transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.short,
                  }),
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                })}
              />
            </Box>
          </Fade>
        </Box>
      </MUIMenuItem>
      <Collapse in={menuOpen && expanded}>
        <Box sx={{ pt: 0.5 }}>
          {subItems.map((subItem) => {
            return (
              <Box key={subItem.subItemTitle}>
                {subItem.elements.map((element) => {
                  return (
                    <Box sx={{ display: 'flex' }} key={element.route}>
                      <MUIMenuItem
                        onClick={() => {
                          navigate(element.route, {
                            unstable_viewTransition: true,
                          });
                        }}
                        selected={pathname === element.route}
                        sx={{ width: '100%', pl: 6, borderRadius: 1, transition: 'background-color 0.3s' }}
                      >
                        <Typography variant="body2">{t(element.elementTitle)}</Typography>{' '}
                        {pathname !== element.route && (
                          <ChevronRight fontSize="small" sx={{ marginLeft: 'auto', opacity: 0.2 }} />
                        )}
                      </MUIMenuItem>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>

        <Box sx={{ mt: 2 }}></Box>
      </Collapse>
    </Box>
  );
};
