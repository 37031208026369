import { useEffect } from 'react';
import { getDefaultStore, useAtomValue, useSetAtom } from 'jotai';
import { appliedFiltersAtom } from 'hooks/useAccountingData';
import {
  clientsAtoms,
  clustersAtoms,
  countriesAtoms,
  currenciesAtoms,
  currencyDateAtom,
  dateFromAtom,
  dateToAtom,
  gamesAtoms,
  normalizeCurrencyAtom,
  normalizeEnabledAtom,
  normalizeGroupByAtom,
  operatorsAtoms,
  playersAtom,
  timeZoneAtom,
  type TFilterAtoms,
} from './accountingFiltersAtoms';

export const useAccountingFiltersValidate = (filtersData: { title: string; atoms: TFilterAtoms }[]) => {
  const setAppliedFilters = useSetAtom(appliedFiltersAtom);

  const errors = filtersData.map((filter) => ({
    data: useAtomValue(filter.atoms.data),
    selected: useAtomValue(filter.atoms.selected),
    isError: useAtomValue(filter.atoms.error),
    setIsError: useSetAtom(filter.atoms.error),
  }));

  useEffect(() => {
    errors.forEach((error) => {
      if (error.isError) {
        error.setIsError(error.selected.length === 0);
      }
    });
  });

  const validate = (): boolean => {
    const passed = errors.reduce((acc, error) => {
      const isValid = error.data.length ? error.selected.length > 0 : true;
      error.setIsError(!isValid);
      if (acc && isValid) {
        return true;
      }
      return false;
    }, true);

    if (passed) {
      const store = getDefaultStore();
      const values = {
        clusters: store.get(clustersAtoms.selected),
        clients: store.get(clientsAtoms.selected),
        operators: store.get(operatorsAtoms.selected),
        games: store.get(gamesAtoms.selected),
        currencies: store.get(currenciesAtoms.selected),
        countries: store.get(countriesAtoms.selected),
        normalizeEnabled: store.get(normalizeEnabledAtom),
        normalizeCurrency: store.get(normalizeCurrencyAtom),
        normalizeGroupBy: store.get(normalizeGroupByAtom),
        currencyDate: store.get(currencyDateAtom),
        dateFrom: store.get(dateFromAtom),
        dateTo: store.get(dateToAtom),
        timezone: store.get(timeZoneAtom),
        players: store.get(playersAtom),
      };
      localStorage.setItem('accountingFilters', JSON.stringify(values));
      setAppliedFilters(values);
    }

    return passed;
  };

  return {
    isError: errors.some((error) => error.isError),
    validate,
  };
};

export default useAccountingFiltersValidate;
