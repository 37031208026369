import type { GridColDef } from '@mui/x-data-grid';
import type { Perms } from 'auth';
import type { TFunction } from 'i18next';

export const generateColumns = (t: TFunction, perms: Perms) =>
  [
    { field: 'playerId', headerName: t('playerBrowser.playerId') },
    { field: 'playerExternalId', headerName: t('playerBrowser.playerExternalId') },
    { field: 'playerInternalId', headerName: t('playerBrowser.playerInternalId') },
    perms.clusters && { field: 'clusterLabel', headerName: t('playerBrowser.cluster') },
    perms.clients && { field: 'clientLabel', headerName: t('playerBrowser.client') },
    perms.operators && { field: 'operatorLabel', headerName: t('playerBrowser.operator') },
    { field: 'country', headerName: t('playerBrowser.country') },
    { field: 'currency', headerName: t('playerBrowser.currency') },
    { field: 'totalSpinCount', headerName: t('playerBrowser.betCount'), align: 'center', headerAlign: 'center' },
    { field: 'totalBuyCount', headerName: t('playerBrowser.buyFeatureCount'), align: 'center', headerAlign: 'center' },
    { field: 'debitAmount', headerName: t('playerBrowser.totalBet'), align: 'right', headerAlign: 'right' },
    { field: 'creditAmount', headerName: t('playerBrowser.totalWin'), align: 'right', headerAlign: 'right' },
    { field: 'tester', headerName: t('playerBrowser.isTester') },
    { field: 'firstTransactionDate', headerName: t('playerBrowser.firstActivity') },
    { field: 'lastTransactionDate', headerName: t('playerBrowser.lastActivity') },
  ].filter(Boolean) as GridColDef[];
