import { useMemo } from 'react';
import { usePerms } from 'hooks/usePerms';
import { generateMenuData } from './generateMenuData';

export const useMenuItems = () => {
  const perms = usePerms();
  const menuItems = useMemo(() => generateMenuData(perms), [perms]);

  return { menuItems };
};
