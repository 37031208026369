import { gql } from '@apollo/client';
import { t } from 'i18next';
import { parseBigNumber, parseCountryCode, parseCurrencyNumber, parseDateStringTz } from 'utils';

interface IRawRecord {
  id: string;
  externalId: string;
  debitAmount: number;
  creditAmount: number;
  playerId: string;
  playerInternalId: string;
  playerExternalId: string;
  operatorLabel: string;
  clusterLabel?: string;
  clientLabel?: string;
  currency: string;
  country: string;
  tester: boolean;
  totalBuyCount: number;
  totalSpinCount: number;
  firstTransactionDate: string;
  lastTransactionDate: string;
}

export interface IPlayerBrowserRawData {
  combinedTransactionsByPlayers?: { items: IRawRecord[]; skip: number; take: number; totalCount: number };
}

export const GET_PLAYERS = gql`
  query GetPlayers($where: JSONObject, $skip: Int, $take: Int, $orderBy: String, $orderDirection: String) {
    combinedTransactionsByPlayers(
      filter: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      totalCount
      skip
      take
      items {
        debitAmount
        creditAmount
        playerId
        playerExternalId
        playerInternalId
        operatorLabel
        clusterLabel
        clientLabel
        currency
        country
        tester
        totalBuyCount
        firstTransactionDate
        lastTransactionDate
        totalSpinCount
      }
    }
  }
`;

export const parsePlayerBrowserData = (rawData: IPlayerBrowserRawData, lang: string, timezone: string) => {
  if (!rawData.combinedTransactionsByPlayers) {
    return { totalCount: 0, data: [] };
  }

  return {
    totalCount: rawData.combinedTransactionsByPlayers.totalCount,
    data: rawData.combinedTransactionsByPlayers.items.map((item) => {
      return {
        id: crypto.randomUUID(),
        playerId: item.playerId,
        playerInternalId: item.playerInternalId,
        playerExternalId: item.playerExternalId,
        clusterLabel: item.clusterLabel,
        operatorLabel: item.operatorLabel,
        clientLabel: item.clientLabel,
        currency: item.currency,
        country: parseCountryCode(item.country, lang),
        debitAmount: parseCurrencyNumber(item.debitAmount),
        creditAmount: parseCurrencyNumber(item.creditAmount),
        totalBuyCount: parseBigNumber(item.totalBuyCount),
        totalSpinCount: parseBigNumber(item.totalSpinCount),
        firstTransactionDate: parseDateStringTz(item.firstTransactionDate, timezone),
        lastTransactionDate: parseDateStringTz(item.lastTransactionDate, timezone),
        tester: item.tester ? t('base.Yes') : t('base.No'),
      };
    }),
  };
};
