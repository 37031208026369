import type { GridColDef } from '@mui/x-data-grid';
import type { Perms } from 'auth';
import type { TFunction } from 'i18next';
import { ENormalizeGroups } from 'hooks/useAccountingFilters';

const alwaysColumns = [
  'debitTotal',
  'creditTotal',
  'refundTotal',
  'transactionsCount',
  'totalBuyBet',
  'totalBuyCount',
  'ggr',
];

const groupByColumns: Record<ENormalizeGroups, string[]> = {
  [ENormalizeGroups.NONE]: ['clusterLabel', 'clientLabel', 'operatorLabel', 'slotLabel', 'currency', ...alwaysColumns],
  [ENormalizeGroups.COUNTRIES]: ['country', ...alwaysColumns],
  [ENormalizeGroups.OPERATORS]: ['operatorLabel', ...alwaysColumns],
  [ENormalizeGroups.GAMES]: ['slotLabel', ...alwaysColumns],
  [ENormalizeGroups.CURRENCIES]: ['currency', ...alwaysColumns],
  [ENormalizeGroups.CLIENTS]: ['clientLabel', ...alwaysColumns],
  [ENormalizeGroups.CLUSTERS]: ['clusterLabel', ...alwaysColumns],
};

export const generateColumns = (t: TFunction, groupBy: ENormalizeGroups, perms: Perms) =>
  [
    perms.clusters && { field: 'clusterLabel', headerName: t('accounting.cluster') },
    perms.clients && { field: 'clientLabel', headerName: t('accounting.client') },
    perms.operators && { field: 'operatorLabel', headerName: t('accounting.operator') },
    { field: 'slotLabel', headerName: t('accounting.game') },
    { field: 'currency', headerName: t('accounting.currency') },
    { field: 'country', headerName: t('accounting.country') },
    { field: 'debitTotal', headerName: t('accounting.totalBet'), align: 'right', headerAlign: 'right' },
    { field: 'creditTotal', headerName: t('accounting.totalWin'), align: 'right', headerAlign: 'right' },
    { field: 'refundTotal', headerName: t('accounting.totalRefund'), align: 'right', headerAlign: 'right' },
    { field: 'transactionsCount', headerName: t('accounting.spin'), align: 'center', headerAlign: 'center' },
    { field: 'totalBuyBet', headerName: t('accounting.totalBuyBet'), align: 'right', headerAlign: 'right' },
    { field: 'totalBuyCount', headerName: t('accounting.buyBetCount'), align: 'center', headerAlign: 'center' },
    { field: 'ggr', headerName: t('accounting.ggr'), align: 'right', headerAlign: 'right' },
  ].filter((column) => column && groupByColumns[groupBy]?.includes(column.field)) as GridColDef[];
