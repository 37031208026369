import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSetLoading } from 'hooks/useLoading';
import type { EUserRole, EUserStatus } from 'hooks/useUser';
import { ERoutes } from 'routing';
import { isSpecificError, showError, showToast } from 'utils';
import { type IScope } from 'views/TeamMembersUser/accessesFeature/permissionMapping';

export enum EUsersBulkAction {
  DELETE = 'DELETE',
  DEACTIVE = 'BLOCK',
  ACTIVE = 'UNBLOCK',
}

export interface IUserInput {
  scopes: IScope[];
  role: EUserRole;
  status?: EUserStatus;
  email?: string;
}

const GET_USERS = gql`
  query Users {
    users {
      createdAt
      lastActiveAt
      email
      role
      id
      status
      scopes {
        clientId
        clusterId
        operatorId
      }
    }
  }
`;

const GET_USER = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      createdAt
      lastActiveAt
      email
      role
      id
      status
      scopes {
        clientId
        clusterId
        operatorId
      }
    }
  }
`;

const ADD_USER = gql`
  mutation AddUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
      password
      email
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      id
    }
  }
`;

const USERS_BULK_ACTION = gql`
  mutation UsersBulkAction($usersBulkActionsInput: UsersBulkActionsInput!) {
    usersBulkAction(usersBulkActionsInput: $usersBulkActionsInput) {
      status
    }
  }
`;

export const useUserManagement = (userId?: string, isNewUser?: boolean) => {
  const [getData, { data, loading, error }] = useLazyQuery(userId ? GET_USER : GET_USERS, {
    variables: { id: userId },
    fetchPolicy: 'network-only',
  });
  const navigate = useNavigate();
  const [usersBulkActionMutation] = useMutation(USERS_BULK_ACTION);
  const [addUserMutation] = useMutation(ADD_USER);
  const [updateUserMutation] = useMutation(UPDATE_USER);
  const { startLoading, endLoading } = useSetLoading();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isNewUser) {
      getData();
    }
  }, [userId]);

  useEffect(() => {
    if (error && !isSpecificError(error, 'Cannot return null for non-nullable field Query.user.')) {
      showError(error);
    }
  }, [error]);

  useEffect(() => {
    if (loading) {
      startLoading('fetchUsers');
    } else {
      endLoading('fetchUsers');
    }
  }, [loading]);

  const addUser = useCallback(async (userInput: IUserInput) => {
    startLoading('fetchUsers');
    try {
      const newUserData = await addUserMutation({ variables: { createUserInput: { ...userInput } } });
      navigate(`${ERoutes.ManagementTeamMembersDetails.replace(':id', newUserData.data.createUser.id)}`, {
        replace: true,
      });
      endLoading('fetchUsers');
      return {
        password: newUserData.data.createUser.password as string,
        email: newUserData.data.createUser.email as string,
      };
    } catch (err) {
      showError(err as Error);
    }
    endLoading('fetchUsers');
  }, []);

  const updateUser = useCallback(async (userInput: IUserInput, id: string) => {
    startLoading('fetchUsers');
    try {
      await updateUserMutation({ variables: { updateUserInput: { id, ...userInput } } });
      showToast({ title: t('team.userUpdated'), severity: 'success' });
      getData();
    } catch (err) {
      showError(err as Error);
      endLoading('fetchUsers');
    }
  }, []);

  const handleUsersBulkAction = useCallback(async (action: EUsersBulkAction, usersIds: string[]) => {
    if (usersIds.length === 0) {
      return;
    }
    try {
      startLoading('fetchUsers');
      const response = await usersBulkActionMutation({
        variables: {
          usersBulkActionsInput: { action, usersIds },
        },
      });
      if (response.data?.usersBulkAction?.status === 'ok') {
        if (action === EUsersBulkAction.DELETE) {
          showToast({ title: t('team.usersDeleted'), severity: 'success' });
        } else {
          showToast({ title: t('team.usersUpdated'), severity: 'success' });
        }
        getData();
        return true;
      }
    } catch (err) {
      showError(err as Error);
      endLoading('fetchUsers');
    }
    return false;
  }, []);

  return { handleUsersBulkAction, rawData: data, getData, updateUser, addUser, error };
};
