import { useEffect, useMemo } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { isAuthorizedAtom, type Perms } from 'auth';
import { useAtomValue } from 'jotai';
import Layout from 'components/Layout';
import { usePerms } from 'hooks';
import AccountingReport from 'views/AccountingReport';
import ChangePassword from 'views/ChangePassword';
import NotFound from 'views/NotFound';
import PlayerBrowser from 'views/PlayerBrowser';
import PlayerBrowserDetails from 'views/PlayerBrowserDetails';
import SignIn from 'views/SignIn';
import TeamMembers from 'views/TeamMembers';
import { TeamMemberUser } from 'views/TeamMembersUser';
import PrivateRoute from './PrivateRoute';
import { ERoutes } from './routes';

const createRouter = (perms: Perms) => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route Component={Layout}>
        <Route path={ERoutes.SignIn} Component={SignIn} />
        <Route Component={PrivateRoute}>
          <Route path={ERoutes.Default} element={<Navigate to={ERoutes.AccountingReport} />} />

          {perms.reportsPlayerBrowser && (
            <>
              <Route path={ERoutes.PlayerBrowser} Component={PlayerBrowser} />
              <Route path={ERoutes.PlayerBrowserDetails} Component={PlayerBrowserDetails} />
            </>
          )}
          {perms.reportsAccounting && <Route path={ERoutes.AccountingReport} Component={AccountingReport} />}
          {perms.team && (
            <>
              <Route path={ERoutes.ManagementTeamMembers} Component={TeamMembers} />
              <Route path={ERoutes.ManagementTeamMembersDetails} Component={TeamMemberUser} />
            </>
          )}
          <Route path={ERoutes.ChangePassword} Component={ChangePassword} />
        </Route>
        <Route path="*" Component={NotFound} />
      </Route>,
    ),
  );
};

export const Router = () => {
  const perms = usePerms();
  const isAuthorized = useAtomValue(isAuthorizedAtom);
  const router = useMemo(() => createRouter(perms), [perms]);

  useEffect(() => {
    if (!isAuthorized) {
      router.navigate(ERoutes.SignIn);
    }
  }, [router, isAuthorized]);

  return <RouterProvider router={router} />;
};

export default Router;
