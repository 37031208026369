import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { atom, useStore, type PrimitiveAtom } from 'jotai';
import { Button } from 'components';
import DateButtonInput, { type IDateButtonInputState } from '../DateButtonInput';
import MultiSelectButtonInput, {
  type IMultiSelectButtonInputOption,
  type IMultiSelectButtonInputState,
} from '../MultiSelectButtonInput';
import NumberButtonInput, { type INumberButtonInputState } from '../NumberButtonInput';
import SelectButtonInput, { type ISelectButtonInputState } from '../SelectButtonInput';
import StringButtonInput, { type IStringButtonInputState } from '../StringButtonInput';
import type {
  IButtonInputsFormAppliedFilter,
  IButtonInputsFormInputData,
  IButtonInputsFormInputType,
  TButtonInputsFormInitialState,
  TButtonInputsFormInputStateMap,
} from './ButtonInputsForm.types';
import ButtonInputsFormMoreButton from './ButtonInputsFormMoreButton';

interface IProps {
  inputsData: Readonly<IButtonInputsFormInputData[]>;
  showingInputs: string[];
  setShowingInputs: (showingInputs: string[]) => void;
  selectOptions: Record<string, IMultiSelectButtonInputOption[]>;
  disabled?: boolean;
  loading?: boolean;
  onAccept: (filters: IButtonInputsFormAppliedFilter[]) => void;
  initialState?: TButtonInputsFormInitialState;
  acceptOnInit?: boolean;
  acceptOnChange?: boolean;
}

const getInputInitialState = (
  type: IButtonInputsFormInputType,
): TButtonInputsFormInputStateMap[IButtonInputsFormInputType] => {
  if (type === 'select') {
    return { value: '' };
  }
  if (type === 'multiselect') {
    return { values: [], operator: 'equal' };
  }
  if (type === 'number') {
    return { value: undefined, value2: undefined, operator: 'equal' };
  }
  if (type === 'date') {
    return { value: '', value2: '', operator: 'greater' };
  }
  return { values: [], operator: 'equal' };
};

export const ButtonInputForm = ({
  showingInputs,
  inputsData,
  setShowingInputs,
  selectOptions,
  loading,
  disabled,
  onAccept,
  initialState,
  acceptOnInit,
  acceptOnChange,
}: IProps) => {
  const { t } = useTranslation();
  const store = useStore();
  const inputs = useMemo(
    () =>
      inputsData.reduce(
        (acc, input) => {
          const inputState = initialState?.[input.key] || getInputInitialState(input.type);
          return { ...acc, [input.key]: { data: input, atom: atom(inputState) } };
        },
        {} as Record<
          string,
          {
            data: IButtonInputsFormInputData;
            atom: PrimitiveAtom<TButtonInputsFormInputStateMap[IButtonInputsFormInputType]>;
          }
        >,
      ),
    [],
  );

  useEffect(() => {
    if (acceptOnChange) {
      const unsubFns = Object.values(inputs).map((input) => store.sub(input.atom, () => onAccept(getCurrentFilters())));
      return () => unsubFns.forEach((fn) => fn());
    }
  }, [acceptOnChange]);

  const toggleInput = useCallback(
    (key: string) => {
      if (showingInputs.includes(key)) {
        setShowingInputs(showingInputs.filter((i) => i !== key));
        const input = inputs[key];
        if (input) {
          store.set(input.atom, getInputInitialState(input.data.type));
        }
      } else {
        setShowingInputs([...showingInputs, key]);
      }
    },
    [showingInputs, inputs, store],
  );

  const getCurrentFilters = useCallback(() => {
    return showingInputs
      .map((inputKey) => {
        const input = inputs[inputKey];
        if (!input) {
          // ts doesn't see that null value is filtered out
          return null as unknown as IButtonInputsFormAppliedFilter;
        }
        return {
          key: input.data.key,
          type: input.data.type,
          state: store.get(input.atom),
        };
      })
      .filter((input) => {
        if (!input) {
          return false;
        }
        if (input.type === 'select' && (input.state as ISelectButtonInputState).value === '') {
          return false;
        }
        if (input.type === 'multiselect' && (input.state as IMultiSelectButtonInputState).values.length === 0) {
          return false;
        }
        if (input.type === 'number' && (input.state as INumberButtonInputState).value === undefined) {
          return false;
        }
        if (input.type === 'date' && (input.state as IDateButtonInputState).value === '') {
          return false;
        }
        if (
          (input.type === 'uuid' || input.type === 'string') &&
          (input.state as IStringButtonInputState).values.length === 0
        ) {
          return false;
        }

        return true;
      });
  }, [showingInputs, inputs, store]);

  const onClickApply = useCallback(() => {
    onAccept(getCurrentFilters());
  }, [getCurrentFilters, onAccept]);

  const onClickClear = useCallback(() => {
    showingInputs.forEach((inputKey) => {
      const input = inputs[inputKey];
      if (input) {
        store.set(input.atom, getInputInitialState(input.data.type));
        if (acceptOnChange) {
          onAccept(getCurrentFilters());
        }
      }
    });
  }, [showingInputs, inputs, store]);

  useEffect(() => {
    if (acceptOnInit) {
      onAccept(getCurrentFilters());
    }
  }, []);

  return (
    <>
      {inputsData
        .filter((input) => showingInputs.includes(input.key))
        .map(({ key }) => {
          const input = inputs[key];
          if (!input) {
            return null;
          }
          if (input.data.type === 'select') {
            return (
              <SelectButtonInput
                key={key}
                atom={input.atom as PrimitiveAtom<ISelectButtonInputState>}
                label={t(input.data.translateKey)}
                options={selectOptions[key] || []}
                disabled={disabled}
              />
            );
          } else if (input.data.type === 'multiselect') {
            return (
              <MultiSelectButtonInput
                key={key}
                atom={input.atom as PrimitiveAtom<IMultiSelectButtonInputState>}
                label={t(input.data.translateKey)}
                options={selectOptions[key] || []}
                loading={loading}
                disabled={disabled}
              />
            );
          } else if (input.data.type === 'string') {
            return (
              <StringButtonInput
                key={key}
                atom={input.atom as PrimitiveAtom<IStringButtonInputState>}
                label={t(input.data.translateKey)}
                disabled={disabled}
              />
            );
          } else if (input.data.type === 'uuid') {
            return (
              <StringButtonInput
                key={key}
                atom={input.atom as PrimitiveAtom<IStringButtonInputState>}
                label={t(input.data.translateKey)}
                disabled={disabled}
                uuidValue={true}
              />
            );
          } else if (input.data.type === 'number') {
            return (
              <NumberButtonInput
                key={key}
                atom={input.atom as PrimitiveAtom<INumberButtonInputState>}
                label={t(input.data.translateKey)}
                disabled={disabled}
              />
            );
          } else if (input.data.type === 'date') {
            return (
              <DateButtonInput
                key={key}
                atom={input.atom as PrimitiveAtom<IDateButtonInputState>}
                label={t(input.data.translateKey)}
                disabled={disabled}
              />
            );
          }
        })}
      <ButtonInputsFormMoreButton inputsData={inputsData} showingInputs={showingInputs} toggleInput={toggleInput} />
      <Button variant="outlined" sx={{ minWidth: 36, width: 36 }} onClick={onClickClear}>
        <DeleteIcon />
      </Button>
      {!acceptOnChange && <Button onClick={onClickApply}>{t('base.apply')}</Button>}
    </>
  );
};

export default ButtonInputForm;
